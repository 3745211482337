import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import React, { useEffect, useState, useRef } from 'react';
import { ProcureDataTable } from '../../common/ProcureDataTable/ProcureDataTable';
import TableHeader from '../../components/tableHeader';
import { localStoreSetObj, localStoreGetObj } from '../../helpers/storage';
import { tableHeadersVesselBuyer, tableHeadersVesselMBA, newVessel } from '../../helpers/vesselsConfig';
import { urlParams } from "../../helpers/urlParams";
import useStyles from './styles';
import FilterCompanies from "../../components/filterCompanies";
import TableActions from '../../components/TableActions/TableActions';
import SimpleReactValidator from 'simple-react-validator';
import { getAllVesselsInfo, editVessel, deleteVessel, addVessel } from '../../services/vesselsService';
import { VesselTableFilter } from '../../components/vesselTableFilter';
import FilterVesselTypes from "../../components/filterVesselTypes";
import FilterVesselSizes from "../../components/filterVesselSizes";
import CheckIcon from '@mui/icons-material/Check';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButton from "@mui/material/IconButton";
import { Link as RouterLink } from "react-router-dom";
import './_vessels.scss';
import t from '../../helpers/languages';

const Vessels = (props) => {

  const vesselInitObject = () => {
    return {
      id: '?',
      name: '',
      company: { name: '', id: '' },
      dwt:'',
      vesselType:{ name: '', id: '' },
      vesselSize:{ name: '', id: '' },
      yearBuilt: null,
      isActive: true,
      imonumber: ''
    }
  };

  const classes = useStyles();

  // const userState = useSelector((state) => {
  //   return {
  //     role: state.users.user.role,
  //     company: state.users.user.company,
  //   };
  // });

  const filters = localStoreGetObj('vesselFilter');

  const [vesselsState, setVesselsState] = useState({
    vessels: [],
    page: {
      page: 1,
      count: 0,
      per_page: 10,
      total: 0,
      order_type: 'asc',
      order_by: 'id'
    },
    filters: {},
    isLoading: true,
    vesselEditIndex: -1,
    vesselEditObject: vesselInitObject(),
    vesselCreateObject: null,
    error: null,
    confirmDialog: {
      message: '',
      title: '',
      onCancel: () => { },
      onConfirm: () => { },
      isLoading: false,
      isOpen: false
    }
  });

  const criteria = urlParams.getUrlParams();
  !criteria.per_page && (criteria.per_page = 10)
  !criteria.page && (criteria.page = 1)

  props.autonomousPage && urlParams.setUrlParams(criteria);

  let filtersData = {};

  let companyId = null;

  if (!props.autonomousPage) {
    const pathName = window.location.pathname;
    const regex = /(\d+)/gm;
    let m;
    m = regex.exec(pathName);
    companyId = m[0];
  }

  if (companyId) {
    filtersData = {
      ...vesselsState.filters,
      companyId: companyId,
      search: filters && filters.vessel && filters.vessel,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: vesselsState.page.order_type,
      order_by: vesselsState.page.order_by,
    };
  } else {
    filtersData = {
      ...vesselsState.filters,
      companyId: filters && filters.company && filters.company.id,
      search: filters && filters.vessel && filters.vessel,
      per_page: criteria.per_page,
      page: criteria.page,
      order_type: vesselsState.page.order_type,
      order_by: vesselsState.page.order_by,
    };
  }


  useEffect(function () {
    getAllVesselsInfo(filtersData).then(res => {
      const { results } = res.data;
      setVesselsState({
        ...vesselsState,
        vessels: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });

      props.autonomousPage && urlParams.setUrlParams({
        page: res.data.page.page,
        per_page: res.data.page.per_page
      });

    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setVesselsState({
        ...vesselsState,
        isLoading: false,
      });
    });
    // eslint-disable-next-line  
  }, []);

  const handleSorting = (event, property) => {
    //TODO implement shorting using local state
    const isAsc = property === vesselsState.page.order_by && vesselsState.page.order_type === 'desc' ? 'asc' : 'desc';

    setVesselsState({
      ...vesselsState,
      isLoading: true,
      order_type: isAsc,
      order_by: property,
    });

    filtersData.order_type = isAsc;
    filtersData.order_by = property;

    getAllVesselsInfo(filtersData).then(res => {
      const { results } = res.data;
      setVesselsState({
        ...vesselsState,
        vessels: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setVesselsState({
        ...vesselsState,
        isLoading: false,
      });
    })
  };

  const handleChangePage = (event, newPage) => {
    setVesselsState({
      ...vesselsState,
      isLoading: true
    });
    const newCriteria = {
      ...vesselsState.filters,
      per_page: vesselsState.page.per_page,
      page: newPage + 1
    };
    getAllVesselsInfo(newCriteria).then(res => {
      setVesselsState({
        ...vesselsState,
        isLoading: false,
        vessels: res.data.results,
        page: res.data.page,
        filters: res.data.filters
      });
      props.autonomousPage && urlParams.setUrlParams(res.data.page);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    //na mpei to company id gia mba member
    setVesselsState({
      ...vesselsState,
      isLoading: true
    });
    const perPage = parseInt(event.target.value, 10);
    const newCriteria = {
      ...vesselsState.filters,
      page: 1,
      per_page: perPage,
    };

    getAllVesselsInfo(newCriteria).then(res => {
      setVesselsState({
        ...vesselsState,
        isLoading: false,
        vessels: res.data.results,
        page: res.data.page,
        criteria: newCriteria
      })
      props.autonomousPage && urlParams.setUrlParams(newCriteria);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setVesselsState({
        ...vesselsState,
        isLoading: false,
      });
    });
  };

  const handleEditVessel = (event, vesselIndex) => {

    event.stopPropagation();

    const vesselEditObject = Object.assign({}, vesselsState.vessels[vesselIndex]);


    setVesselsState({
      ...vesselsState,
      vesselEditIndex: vesselIndex,
      vesselEditObject: vesselEditObject
    });

  };

  const onEditableVesselChange = (event, property) => {

    let newValue = '';
    if (property === 'isActive') {
      newValue = event.target.checked;
    } else {
      newValue = event.target.value;
    }

    const newEditVesselItem = Object.assign({}, vesselsState.vesselEditObject);
    newEditVesselItem[property] = newValue;


    setVesselsState({
      ...vesselsState,
      vesselEditObject: newEditVesselItem
    });
  };
  const [, forceUpdate] = useState();
  //todo
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, showMessages: true }));


  const handleSaveVessel = (event, props) => {
    event.preventDefault();
    setVesselsState({
      ...vesselsState,
      isLoading: true
    });
    const newEditVesselItem = Object.assign({}, vesselsState.vesselEditObject);

    if (validator.current.allValid()) {
      editVessel(newEditVesselItem.id, newEditVesselItem)
        .then(re => {
          const editedVessel = re.data;
          setVesselsState(oldVessels => {
            return {
              ...oldVessels,
              vessels: oldVessels.vessels.map(vessel => {
                return vessel.id === editedVessel.id
                  ? re.data : vessel
              }),
              vesselEditIndex: -1
            }
          });
        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setVesselsState({
            ...vesselsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
      setVesselsState({
        ...vesselsState,
        isLoading: false,
      });
    }

  };

  const handleCloseEditVessel = (e, index) => {
    setVesselsState({
      ...vesselsState,
      vesselEditIndex: -1
    })
  };

  const handleDeleteVessel = (event, index) => {

    const vesselForDeletion = vesselsState.vessels[index];
    setVesselsState({
      ...vesselsState,
      confirmDialog: {
        message: 'Are you sure you want to delete?',
        title: 'Delete Vessel',
        onCancel: () => {
          setVesselsState({
            ...vesselsState,
            confirmDialog: {
              ...vesselsState.confirmDialog,
              isOpen: false
            }
          })
        },
        onConfirm: () => {
          deleteVessel(vesselForDeletion.id).then(() => {
            const newVessels = [...vesselsState.vessels];
            newVessels.splice(index, 1);
            setVesselsState({
              ...vesselsState,
              vessels: newVessels,
            });
          })
            .catch(err => {
              alert('ERROR')
            });
        },
        isLoading: false,
        isOpen: true
      }
    });
  };

  const handleAddNewVessel = (e) => {
    e.preventDefault();

    setVesselsState({
      ...vesselsState,
      vesselCreateObject: newVessel()
    });
    setTimeout(() => {
      document.querySelector("#root").scrollTo(0, 500);
    }, 0);
  };

  const onSaveNewVessel = () => {
    //TODO Remaining to resolve HTTP - 400 bad request

    let newVessel = {};
    newVessel.name = vesselsState.vesselCreateObject.name;
    const simpleCompanyUser = {
      id: props.userState.user.userCompany,
      name: props.userState.user.userName
    }
    props.userState.user.userRole === 'MBA' ? newVessel.company = vesselsState.vesselCreateObject.company : newVessel.company = simpleCompanyUser;

    newVessel.yearBuilt = vesselsState.vesselCreateObject.yearBuilt;
    newVessel.dwt = vesselsState.vesselCreateObject.dwt;
    newVessel.vesselType = vesselsState.vesselCreateObject.vesselType;
    newVessel.vesselSize = vesselsState.vesselCreateObject.vesselSize;
    newVessel.isActive = vesselsState.vesselCreateObject.isActive;
    newVessel.imonumber = vesselsState.vesselCreateObject.imonumber;

    if (validator.current.allValid()) {
      addVessel(newVessel)
        .then(res => {
          setVesselsState({
            ...vesselsState,
            vessels: [...vesselsState.vessels, res.data],
            vesselCreateObject: null,
            vesselEditIndex: -1,
            isLoading: false,
          })

        }).catch((error) => {
          // dispatch(messagesActions.openSnackbar({
          //   variant: 'error ',
          //   message: ' ' + error,
          // }));
          setVesselsState({
            ...vesselsState,
            isLoading: false,
          });
        });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };


  const onEditNewVesselObject = (e, property) => {
    const newValue = e.target.value;

    const newEditVesselItem = Object.assign({}, vesselsState.vesselCreateObject);
    newEditVesselItem[property] = newValue;

    setVesselsState({
      ...vesselsState,
      vesselCreateObject: newEditVesselItem
    });
  };

  const filterData = (data) => {
    setVesselsState({
      ...vesselsState,
      isLoading: true
    });

    let filtersData = '';
    if (props.autonomousPage) {
      filtersData = {
        ...vesselsState.filters,
        search: data && data.vessel,
        companyId: data && data.company && data.company.id
      };
    } else {
      filtersData = {
        ...vesselsState.filters,
        search: data && data.vessel,
        companyId: companyId
      };
    }

    getAllVesselsInfo(filtersData).then(res => {
      const { results } = res.data;

      setVesselsState({
        ...vesselsState,
        vessels: results,
        isLoading: false,
        page: res.data.page,
        filters: res.data.filters
      });
      localStoreSetObj('vesselFilter', data);
    }).catch((error) => {
      // dispatch(messagesActions.openSnackbar({
      //   variant: 'error ',
      //   message: ' ' + error,
      // }));
      setVesselsState({
        ...vesselsState,
        isLoading: false,
      });
    });

  };

  const selectedCompany = (data, action) => {

    const newEditVesselItem = Object.assign({},
      action === 'edit'
        ? vesselsState.vesselEditObject
        : vesselsState.vesselCreateObject
    );

    newEditVesselItem.company = data.company;


    setVesselsState({
      ...vesselsState,
      [
        action === 'edit'
          ? 'vesselEditObject'
          : 'vesselCreateObject'
      ]: newEditVesselItem
    });
  };

  
  const selectedVesselType = (data, action) => {

    const newEditVesselItem = Object.assign({},
      action === 'edit'
        ? vesselsState.vesselEditObject
        : vesselsState.vesselCreateObject
    );

    newEditVesselItem.vesselType = data.vesselType;


    setVesselsState({
      ...vesselsState,
      [
        action === 'edit'
          ? 'vesselEditObject'
          : 'vesselCreateObject'
      ]: newEditVesselItem
    });
  };

  const selectedVesselSize = (data, action) => {

    const newEditVesselItem = Object.assign({},
      action === 'edit'
        ? vesselsState.vesselEditObject
        : vesselsState.vesselCreateObject
    );

    newEditVesselItem.vesselSize = data.vesselSize;


    setVesselsState({
      ...vesselsState,
      [
        action === 'edit'
          ? 'vesselEditObject'
          : 'vesselCreateObject'
      ]: newEditVesselItem
    });
  };

  const tableFilterComponent = () => {
    return <VesselTableFilter
      company={(filters && filters.company) ? filters.company : vesselsState.filters.company}
      vessel={(filters && filters.vessel) ? filters.vessel : vesselsState.filters.vessel}
      autonomousPage={props.autonomousPage}
      getFilterData={filterData}
    />
  };

  const tableHeaderComponent = () => {
    return <TableHeader
      handleSorting={handleSorting}
      headCells={props.userState.user.userRole !== 'MBA' ? tableHeadersVesselBuyer : tableHeadersVesselMBA}
      onSelectAllClick={() => { }}
      sort={false}
      order={''}
      orderBy={''}
      rowCount={''} />;
  };


  const tableBodyComponent = () => {
    return (
      <TableBody>
        {Array.isArray(vesselsState.vessels) && vesselsState.vessels.map((vessel, index) => {
          return (
            <TableRow
              key={index}
              className={classes.tableRow}
            >{
                (vesselsState.vesselEditIndex !== index) && index !== '?'
                  ? <>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        style={{ padding: '0px' }}
                        component={RouterLink}
                        to={{ pathname: `/vessels/${vessel.id}/products-inventory/`,vessel:vessel.name }}
                      >
                        <ManageSearchIcon></ManageSearchIcon>
                      </IconButton>
                    </TableCell>
                    <TableCell>{vessel.name}</TableCell>
                    {props.userState.user.userRole === 'MBA' && <TableCell>{vessel.company.name}</TableCell>}
                    <TableCell>{vessel.yearBuilt}</TableCell>
                    <TableCell>{vessel.dwt}</TableCell>
                    <TableCell>{vessel.vesselType?.name}</TableCell>
                    <TableCell>{vessel.vesselSize?.name}</TableCell>
                    <TableCell>{vessel.imonumber}</TableCell>
                    <TableCell>{vessel.isActive && <CheckIcon />}</TableCell>
                  </> :
                  <>
                    <TableCell onClick={e => e.stopPropagation()}>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={vesselsState.vesselEditObject.name || ''}
                          onChange={e => onEditableVesselChange(e, 'name')}
                          onBlur={() => validator.current.showMessageFor('name')}
                        />{validator.current.message('name', vesselsState.vesselEditObject.name, 'required')}
                      </FormControl>
                    </TableCell>
                    {props.userState.user.userRole === 'MBA' && (
                      <TableCell onClick={e => e.stopPropagation()}>
                        <FormControl variant="outlined" fullWidth>
                          <FilterCompanies
                            company={vesselsState.vesselEditObject.company || null}
                            getSelectedCompany={(data) => selectedCompany(data, 'edit')}
                            buyer={true}
                            errorMessage={null}
                          />
                          {validator.current.message('comnpany', vesselsState.vesselEditObject.company.id, 'required|numeric')}
                        </FormControl>
                      </TableCell>
                    )
                    }
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={vesselsState.vesselEditObject.yearBuilt || ''}
                          onChange={e => onEditableVesselChange(e, 'yearBuilt')}
                          onBlur={() => validator.current.showMessageFor('yearBuilt')}
                        />{validator.current.message('yearBuilt', vesselsState.vesselEditObject.yearBuilt, 'numeric')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={vesselsState.vesselEditObject.dwt || ''}
                          onChange={e => onEditableVesselChange(e, 'dwt')}
                          onBlur={() => validator.current.showMessageFor('dwt')}
                        />{validator.current.message('dwt', vesselsState.vesselEditObject.dwt, 'numeric')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterVesselTypes
                          vesselType={vesselsState.vesselEditObject.vesselType || null}
                          getSelectedVesselType={(data) => selectedVesselType(data, 'edit')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <FilterVesselSizes
                          vesselSize={vesselsState.vesselEditObject.vesselSize || null}
                          getSelectedVesselSize={(data) => selectedVesselSize(data, 'edit')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          value={vesselsState.vesselEditObject.imonumber || ''}
                          onChange={e => onEditableVesselChange(e, 'imonumber')}
                          onBlur={() => validator.current.showMessageFor('imonumber')}
                        />{validator.current.message('imonumber', vesselsState.vesselEditObject.imonumber, 'numeric')}
                      </FormControl>
                    </TableCell>
                    <TableCell onClick={e => e.stopPropagation()}>
                      <FormControl variant="outlined" fullWidth>
                        <Checkbox
                          checked={vesselsState.vesselEditObject.isActive||false}
                          onChange={e =>onEditableVesselChange(e, 'isActive')}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </FormControl>
                    </TableCell>
                  </>}
              <TableCell>
                <TableActions config={{
                  edit: {
                    enabled: vesselsState.vesselEditIndex !== index,
                    callback: (e) => handleEditVessel(e, index)
                  },
                  delete: {
                    enabled: vesselsState.vesselEditIndex !== index,
                    callback: (e) => handleDeleteVessel(e, index)
                  },
                  close: {
                    enabled: vesselsState.vesselEditIndex === index,
                    callback: (e) => handleCloseEditVessel(e, index)
                  },
                  save: {
                    enabled: vesselsState.vesselEditIndex === index,
                    callback: (e) => handleSaveVessel(e, props)
                  }
                }} />
              </TableCell>
            </TableRow>
          );
        })}{
          vesselsState.vesselCreateObject &&
          <TableRow
            className={classes.tableRow}
          >
            <TableCell onClick={e => e.stopPropagation()}>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={vesselsState.vesselCreateObject.name || ''}
                  onChange={e => onEditNewVesselObject(e, 'name')}
                  onBlur={() => validator.current.showMessageFor('name')}
                />{validator.current.message('name', vesselsState.vesselCreateObject.name, 'required')}
              </FormControl>
            </TableCell>
            {props.userState.user.userRole === 'MBA' && (
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FilterCompanies
                    company={vesselsState.vesselCreateObject.company || null}
                    getSelectedCompany={(data) => selectedCompany(data, 'create')}
                    buyer={true}
                    errorMessage={null}
                  />
                  {validator.current.message('company', vesselsState.vesselCreateObject.company.id, 'required|numeric')}
                </FormControl>
              </TableCell>
            )}
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={vesselsState.vesselCreateObject.yearBuilt || ''}
                  onChange={e => onEditNewVesselObject(e, 'yearBuilt')}
                  onBlur={() => validator.current.showMessageFor('yearBuilt')}
                />{validator.current.message('yearBuilt', vesselsState.vesselCreateObject.yearBuilt, 'numeric')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    variant="outlined"
                    value={vesselsState.vesselCreateObject.dwt || ''}
                    onChange={e => onEditNewVesselObject(e, 'dwt')}
                    onBlur={() => validator.current.showMessageFor('dwt')}
                  />{validator.current.message('dwt', vesselsState.vesselCreateObject.dwt, 'numeric')}
                </FormControl>
             </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FilterVesselTypes
                    vesselType={vesselsState.vesselCreateObject.vesselType || null}
                    getSelectedVesselType={(data) => selectedVesselType(data, 'create')}
                  />
                </FormControl>
              </TableCell>
              <TableCell onClick={e => e.stopPropagation()}>
                <FormControl variant="outlined" fullWidth>
                  <FilterVesselSizes
                    vesselSize={vesselsState.vesselCreateObject.vesselSize || null}
                    getSelectedVesselSize={(data) => selectedVesselSize(data, 'create')}
                  />
                </FormControl>
              </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  value={vesselsState.vesselCreateObject.imonumber || ''}
                  onChange={e => onEditNewVesselObject(e, 'imonumber')}
                  onBlur={() => validator.current.showMessageFor('imonumber')}
                />{validator.current.message('imonumber', vesselsState.vesselCreateObject.imonumber, 'numeric')}
              </FormControl>
            </TableCell>
            <TableCell onClick={e => e.stopPropagation()}>
              <FormControl variant="outlined" fullWidth>
                <Checkbox
                  checked={vesselsState.vesselCreateObject.isActive}
                  onChange={e => onEditNewVesselObject(e, 'isActive')}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </FormControl>
            </TableCell>
            <TableCell>
              <TableActions config={{
                edit: {
                  enabled: false,
                  callback: () => null
                },
                delete: {
                  enabled: false,
                  callback: () => null
                },
                close: {
                  enabled: true,
                  callback: (e) => setVesselsState({ ...vesselsState, vesselCreateObject: null })
                },
                save: {
                  enabled: true,
                  callback: (e) => onSaveNewVessel()
                }
              }}>
              </TableActions>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    );
  }
  return (
    <>
      <ProcureDataTable
        toolbarTitle={t('VESSELS')}
        pagination={{
          total: vesselsState.page.total || 0,
          page: vesselsState.page.page - 1 || 0,
          perPage: vesselsState.page.per_page || 10,
          count: vesselsState.page.count || 0,
          handleChangePage: handleChangePage,
          handleChangeRowsPerPage: handleChangeRowsPerPage,
        }}
        addNewRawBtnTitle={''}
        newRowActionAvailable={true}
        onNewRowClick={handleAddNewVessel}
        newRowBtnEnabled={!vesselsState.vesselCreateObject}
        confirmDialog={vesselsState.confirmDialog.message.length > 0 ? vesselsState.confirmDialog : vesselsState.confirmDialog}
        tableHeader={tableHeaderComponent}
        tableBody={tableBodyComponent}
        tableFilter={props.userState.user.userRole === 'MBA' && props.pageFilter !== false ? tableFilterComponent : undefined}
        autonomousPage={props.autonomousPage}
        isLoading={vesselsState.isLoading}
      />
    </>
  );
};


export default Vessels;
